import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../atoms/inputs/Button';
import { useLocation } from '@reach/router';

export default function SpeakerApplicant() {
  const { pathname } = useLocation();
  const [name, setName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const inputStyles =
    'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-base bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!name || !businessName || !email) {
      return setErrorMessage('Please fill in all required fields');
    }

    try {
      await fetch('/.netlify/functions/speaker-applicant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          businessName,
          email,
          url,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            setName('');
            setBusinessName('');
            setEmail('');
            setUrl('');
            setErrorMessage(
              'Thank you for your interest in securing Devon as your Conference Speaker.'
            );
            setIsLoading(false);
          } else {
            setErrorMessage('An error occurred. Please try again later.');
            setIsLoading(false);
          }
          return res.json();
        })
        .catch((err) => console.log(err.message));
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div
      id="speaker-applicant-form"
      className="relative z-10 text-center w-full bg-white px-0 lg:px-10 py-4 xl:py-8 rounded-3xl"
    >
      <h3 className="text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4">
        <span className="text-highlight">Book your Speaker Today</span>
      </h3>
      <p className="text-lg lg:text-xl max-w-3xl mb-8 mx-auto">
        Dive into the realm of Generative AI and discover its powerful role in
        revolutionizing local advertising. Secure Devon as your Conference
        Speaker.
      </p>
      <form onSubmit={handleSubmit} className="mx-auto max-w-lg">
        <div className="flex flex-col  items-start justify-start w-full">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Name"
          />
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Business Name"
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder="Email"
          />
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className={inputStyles + ' mb-4'}
            placeholder="URL of Website, Event or Activity"
          />
        </div>
        <p
          className={`my-3 text-xs lg:text-base sm:mt-3 text-grey-25 font-light max-w-xs md:max-w-[unset] mx-auto`}
        >
          By providing your email, you agree to our{' '}
          <Link to="/legal/terms" className=" text-highlight">
            {' '}
            terms of service{' '}
          </Link>
          .
        </p>
        <div className="mx-auto flex items-center justify-center h-full relative">
          <Button
            type="submit"
            label="Submit"
            className="min-w-[200px]"
            isLoading={isLoading}
            minWidth={213}
            id="speaker-applicant-button"
          />
        </div>
        {errorMessage && (
          <p className="text-center my-4 lg:my-6 text-default text-xl lg:text-2xl">
            {errorMessage}
          </p>
        )}
      </form>
    </div>
  );
}
